import { isNilOrEmpty, omitBy, map, renameKeys } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi, http } from 'lib/http-client'

const supplierSchema = new schema.Entity('suppliers')

const SupplierService = {
  async list({ query = '', type, invitationStatus }) {
    const searchParams = omitBy(isNilOrEmpty, {
      q: query,
      type,
      invitationStatus,
    })

    const response = await adminApi.get(`suppliers`, { searchParams }).json()

    const suppliers = map(renameKeys({ _id: 'id' }), response)

    const normalized = normalize(suppliers, [supplierSchema])
    const entities = normalized.entities?.suppliers ?? {}
    const order = normalized.result ?? []

    return {
      entities,
      order,
    }
  },

  async read(id) {
    return adminApi.get(`companies/${id}`).json()
  },
  async publish(organizationId, payload) {
    const json = {
      organizationId,
      companyDetails: { ...payload, type: 'supplier', email: payload.commercial.email },
    }
    return adminApi.post(`companies`, { json }).json()
  },
  async update(id, payload) {
    return adminApi.patch(`companies/${id}`, { json: payload }).json()
  },
  async download(id, companyId) {
    const { signedUrl } = await adminApi
      .get(`companies/${companyId}/document-download/${id}`)
      .json()

    return http.get(signedUrl).blob()
  },
}

export { SupplierService }
