import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { createContext, useContext, useEffect } from 'react'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'
import { jobModule, jobFilters$ } from 'modules/job'

const Container = styled.div(apply('flex w-full'))
const Details = styled(Form)(apply('flex-1 flex flex-row items-center'), {
  gap: '1rem',
})
export const FormInput = styled(FormInputBase)(apply('bg-white'))

const FilterContext = createContext(undefined)
const FilterProvider = ({ children, value }) => (
  <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
)
export const useFilter = () => useContext(FilterContext)

const FilterBar = ({ jobFilters, selectedTab, children, filterJobsFn }) => {
  const { from, to, filterQuery, project } = jobFilters

  const onSubmit = (values) => filterJobsFn(null, values)

  //   Cleanup Job Filters
  useEffect(() => () => jobModule.reset(), [])

  return (
    <Container>
      <FormProvider
        enableReinitialize
        initialValues={{
          // We pass null when either date filters are `undefined`
          // so that DateField displays the placeholder instead of `today`
          from: from ?? null,
          to: to ?? null,
          filterQuery: filterQuery ?? '',
          project,
        }}
        onSubmit={onSubmit}
      >
        <FilterProvider value={{ onSubmit, jobFilters, selectedTab }}>
          <Details>{children}</Details>
        </FilterProvider>
      </FormProvider>
    </Container>
  )
}

const ConnectedFilterBar = connect(() => ({
  jobFilters: jobFilters$,
}))(FilterBar)

export { ConnectedFilterBar as FilterBar }
