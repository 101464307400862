/* eslint-disable camelcase */
import {
  prop,
  path,
  pickThenRename,
  filter,
  isNotNilOrEmpty,
} from '@seedcloud/ramda-extra'

import { isPilotDocumentAsset, isStaffDocumentAsset } from './documentFilters'

import { PUBLISH_TYPES } from 'constants/publishType'
import { safelyParseDate } from 'utils/safelyParseDate'

const buildMapboxGeoJson = (coordinates, address) => ({
  geometry: {
    coordinates,
    type: 'Point',
  },
  place_name: address,
})

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

const getInitialSelectedAllField = (field, selectedAllSupplier) => {
  if (field === 'selectedAllSupplier') {
    return selectedAllSupplier
  }
  return !selectedAllSupplier
}
const getInitialNetworkPublishType = ({
  selectedAllPilot,
  selectedAllSupplier,
  selectedPilot,
  selectedSupplier,
}) => {
  // We want to prioritize the selected values first
  if (selectedPilot) return PUBLISH_TYPES.INTERNAL
  if (selectedSupplier) return PUBLISH_TYPES.SUPPLIER

  // If those values are empty then check the *all field
  if (selectedAllPilot) return PUBLISH_TYPES.INTERNAL
  if (selectedAllSupplier) return PUBLISH_TYPES.SUPPLIER

  return PUBLISH_TYPES.INTERNAL
}

const getJobInfo = ({
  reference,
  createdAt,
  scheduledAt,
  template,
  commercialSettings,
  company,
  product,
  description = 'No description',
  instructions = 'No instructions',
  engagedAt,
  startedAt,
  finishedAt,
  uploadCode,
  acceptedBy,
  acceptedAt,
  rejectedBy,
  rejectedAt,
  engagedBy,
  pilotCompany,
  internalProjectId,
  publishType,
  selectedPilot,
  selectedPilotCategory,
  selectedSupplier,
  selectedAllPilot,
  selectedAllSupplier,
  publishedBy,
  publishedAt,
  pricing,
  acceptTermsConditions,
  equipmentUsed,
  equipmentFee,
  calloutFee,
  marketFee,
  ratings,
  feedback,
  address,
  radius,
  project,
  location: jobLocation,
  customer,
}) => ({
  reference,
  createdAt: safelyParseDate(createdAt),
  scheduledAt: safelyParseDate(scheduledAt),
  startedAt: safelyParseDate(startedAt),
  engagedAt: safelyParseDate(engagedAt),
  finishedAt: safelyParseDate(finishedAt),
  template,
  commercialSettings,
  description,
  instructions,
  uploadCode,
  acceptedBy,
  acceptedAt: safelyParseDate(acceptedAt),
  rejectedBy,
  rejectedAt: safelyParseDate(rejectedAt),
  engagedBy,
  pilotCompany,
  internalProjectId,
  publishType,
  selectedPilot: {
    id: prop('id', selectedPilot),
    name: path(['userId', 'fullName'], selectedPilot),
  },
  selectedPilotCategory: {
    id: prop('id', selectedPilotCategory),
    name: isNotNilOrEmpty(selectedPilotCategory)
      ? `${prop('description', selectedPilotCategory)} ${formatCurrency(
          prop('rate', selectedPilotCategory)
        )} / hr`
      : undefined,
  },
  selectedSupplier: {
    id: prop('id', selectedSupplier),
    companyName: path(['company', 'companyName'], selectedSupplier),
  },
  publishTypeNetwork: getInitialNetworkPublishType({
    selectedAllPilot,
    selectedAllSupplier,
    selectedPilot,
    selectedSupplier,
  }),
  selectedAllPilot: getInitialSelectedAllField('selectedAllPilot', selectedAllSupplier),
  selectedAllSupplier: getInitialSelectedAllField(
    'selectedAllSupplier',
    selectedAllSupplier
  ),
  pricing,
  publishedBy,
  publishedAt: safelyParseDate(publishedAt),
  company,
  acceptTermsConditions,
  equipmentUsed,
  equipmentFee,
  calloutFee,
  marketFee,
  ratings,
  feedback,
  product,
  project,
  radius,
  location: buildMapboxGeoJson(jobLocation?.coordinates, address),
  customer,
})

const getJobDocuments = (documents, organizationDocument) => {
  const pilotUploadedDocuments = filter(isPilotDocumentAsset, documents)
  const organizationUploadedDocuments = filter(
    isStaffDocumentAsset,
    organizationDocument
  )
  return {
    uploadedByPilot: pilotUploadedDocuments,
    uploadedByOrganization: organizationUploadedDocuments,
  }
}

const getContactInfo = ({ orderedBy }) => {
  const client = pickThenRename(
    ['id', 'fullName', 'phoneNumber', 'email'],
    {
      fullName: 'name',
      phoneNumber: 'mobile',
    },
    orderedBy ?? {}
  )

  return { client }
}

export default function (
  inspectedJob,
  jobDocumentList,
  organizationDocumentList,
  pilotCompany
) {
  const { orderedBy, ...inspectedJobData } = inspectedJob

  const jobInfo = getJobInfo({ pilotCompany, ...inspectedJobData })
  const documents = getJobDocuments(jobDocumentList, organizationDocumentList)
  const contactInfo = getContactInfo({ orderedBy })

  return { jobInfo, documents, contactInfo }
}
