import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex justify-between items-center bg-blue-lightest mb-5'),
  ({ hideTopBorder }) => ({
    ...apply(hideTopBorder ? '' : 'mt-3'),
    borderTop: hideTopBorder ? '0px' : '1px #CCCCCC',
    borderBottom: '1px #CCCCCC',
    borderStyle: 'solid',
    borderInline: 0,
  })
)
const TabsContainer = styled.div(apply('flex w-full'), {
  overflowX: 'auto',
})
const Tab = styled.div(
  apply('flex justify-center w-full items-center text-center', {
    cursor: 'pointer',
    minWidth: '126px',
    height: '61px',
  }),
  ({ selected, theme }) => ({
    fontWeight: 'bold',
    backgroundColor: selected ? theme.colors.primary : '#ffffff',
    color: selected ? '#ffffff' : '#555555',
    '&:hover': { backgroundColor: selected ? theme.colors.primary : '#f7fcff' },
  })
)

function Tabs({ tabs, selectedTab, hideTopBorder }) {
  return (
    <Container hideTopBorder={hideTopBorder}>
      <TabsContainer>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            selected={selectedTab === tab.id}
            onClick={tab.onClick}
            data-test-id={tab.testId}
          >
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export { Tabs }
