import { useFormikContext } from 'formik'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { SelectField } from 'components/common/SelectField'
import { COMPANY_TYPE_DISPLAY_NAMES } from 'constants/companyTypes'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})

const typeOptions = Object.entries(COMPANY_TYPE_DISPLAY_NAMES).map(([id, label]) => ({
  id,
  value: id,
  label,
}))

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

function CreateCompanyForm({
  isEditing,
  isCreatingCompany,
  pilotCategoriesList,
  ...props
}) {
  const { setFieldValue } = useFormikContext()

  const onSelectChange = (name) => (selectedOption) => {
    setFieldValue(name, selectedOption?.value)
  }

  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${description} ${formatCurrency(rate)} / hr`,
  }))

  return (
    <Container {...props}>
      <Details>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput
              name="companyDetails.companyName"
              type="text"
              disabled={!isEditing}
              label="Company Name"
            />
            <FormInput
              name="companyDetails.abn"
              type="text"
              disabled={!isEditing}
              label="abn"
            />
          </Row>
          <Row>
            <SelectField
              name="companyDetails.type"
              label="Company Type"
              type="select"
              onChange={onSelectChange('type')}
              isDisabled={!isEditing}
              options={typeOptions}
              placeholder="Select Type"
              isSearchable={false}
              containerProps={{ style: apply('flex-1') }}
            />
          </Row>
          <Row>
            <LocationField
              name="companyDetails.location"
              label="Address"
              disabled={!isEditing}
            />
          </Row>
          <Row>
            <SelectField
              name="companyDetails.pilotCategoryId"
              label="Pilot Category"
              emptyOption={{
                id: undefined,
                value: undefined,
              }}
              onChange={({ id }) => {
                setFieldValue('companyDetails.pilotCategoryId', id)
              }}
              options={pilotCategoryOptions}
              placeholder="Select Pilot Category"
            />
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { CreateCompanyForm }
