import { connect } from '@seedcloud/stateless'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { InvitePilot } from './InvitePilot'

import { API_TYPES } from 'lib/http-client'
import { createCompanyModule } from 'modules/legacy-company'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { getCompanyAndOrganizationId } from 'utils/getOrganizationId'

const { module: pilotModule } = createPilotModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule(API_TYPES.WEB)

const { module: companyModule } = createCompanyModule(API_TYPES.WEB)

const { inviteCompanyPilot } = companyModule

const ConnectedInvitePilot = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
}))(InvitePilotContainer)

function InvitePilotContainer({ pilotCategoriesList }) {
  const { companyId } = useParams()
  const [companyName, setCompanyName] = useState('')
  const [isInvitingPilot, setIsInvitingPilot] = useState(false)
  const history = useHistory()

  async function getCompanyName() {
    const { companyName } = await getCompanyAndOrganizationId()
    setCompanyName(companyName)
  }

  useEffect(() => {
    pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: false,
    })
    getCompanyName()
    pilotModule.resetUploadAvatar()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [])

  const initialValues = {
    avatar: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    pilotCategoryId: '',
    companyName,
  }

  const invitePilotProps = {
    pilotCategoriesList,
    initialValues,
    isInvitingPilot,
    setAvatarFile: ([file]) => {
      pilotModule.setAvatarFile(null, file)
    },
    handleSubmit: async (values) => {
      setIsInvitingPilot(true)
      const company = await inviteCompanyPilot(null, {
        companyId,
        userDetails: values,
      })
      if (company) {
        history.push(`/pilot/${company._id}`)
      }
      setIsInvitingPilot(false)
    },
  }

  return <InvitePilot {...invitePilotProps} />
}

export { ConnectedInvitePilot }
