import { useHistory, useParams } from 'react-router-dom'

import ArrowLeft from 'assets/arrowLeft.svg'
import { Button } from 'components/common/Button'
import { TabEditing } from 'components/common/TabEditing'
import { ScrollTabs } from 'components/common/Tabs'
import { JOB_STATUSES } from 'constants/jobs'
import { PUBLISH_TYPES } from 'constants/publishType'
import { styled, apply } from 'lib/styled'

const jobStatusMapper = {
  [JOB_STATUSES.NEW]: 'new',
  [JOB_STATUSES.QUEUE]: 'in queue',
  [JOB_STATUSES.PUBLISHED]: 'published',
  [JOB_STATUSES.ENGAGED]: 'engaged',
  [JOB_STATUSES.DELIVERED]: 'delivered',
  [JOB_STATUSES.IN_REVIEW]: 'in review',
  [JOB_STATUSES.COMPLETED]: 'completed',
  [JOB_STATUSES.CANCELLED]: 'cancelled',
  [JOB_STATUSES.DISPUTED]: 'disputed',
}

const jobNetworkStatusMapper = {
  [JOB_STATUSES.NEW]: 'new',
  [JOB_STATUSES.QUEUE]: 'in queue',
  [JOB_STATUSES.PUBLISHED]: 'published',
  [JOB_STATUSES.ENGAGED]: 'engaged',
  [JOB_STATUSES.DELIVERED]: 'delivered',
  [JOB_STATUSES.IN_REVIEW]: 'in review network',
  [JOB_STATUSES.IN_REVIEW_NETWORK]: 'in review',
  [JOB_STATUSES.COMPLETED]: 'completed',
  [JOB_STATUSES.CANCELLED]: 'cancelled',
  [JOB_STATUSES.DISPUTED]: 'disputed',
}

const Navigation = styled.div(apply('bg-white flex flex-column sticky pin-t z-4'))

const Container = styled.div(apply('px-2 w-full items-center py-6 flex'), {
  gap: '1rem',
})
const ScrollTabsContainer = styled.div({
  overflowX: 'auto',
  whiteSpace: 'nowrap',
})

const GoBackBtn = styled.img(apply('block p-2', { cursor: 'pointer' }))

const Title = styled.span(apply('text-xl font-bold flex-1'), {
  textTransform: 'capitalize',
})

function NavigationTab({
  onGoBackClicked,
  title = 'job_status_in_review',
  isEditing,
  setIsEditing,
  showEditingTab,
  publishType,
}) {
  const history = useHistory()
  const { id } = useParams()

  const titleMapper =
    publishType === PUBLISH_TYPES.NETWORK ? jobNetworkStatusMapper : jobStatusMapper

  return (
    <Navigation>
      <Container>
        <GoBackBtn src={ArrowLeft} onClick={onGoBackClicked} />
        <Title>{`${title in titleMapper ? titleMapper[title] : title} Job`}</Title>
        {showEditingTab && (
          <TabEditing
            style={{ marginLeft: 'auto' }}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        )}
        {jobStatusMapper[title] === 'completed' && (
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={() => history.push(`/job/${id}/order`)}
          >
            View Order Details
          </Button>
        )}
      </Container>
      <ScrollTabsContainer>
        <ScrollTabs deps={[title]} />
      </ScrollTabsContainer>
    </Navigation>
  )
}

export { NavigationTab }
