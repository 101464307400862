import { pick } from '@seedcloud/ramda-extra'

export const roleKey = 'https://nearbysky.com/roles'

export const ROLES = {
  ADMIN: 'organization_admin',
  STAFF: 'organization_staff',
  // Non Admin Roles
  PILOT: 'pilot',
  COMMERCIAL: 'commercial',
  KEY_CONTACT: 'key_contact',
  BILLING: 'billing',
  HERO_PILOT: 'hero_pilot',
  ONBOARDING_PILOT: 'onboarding_pilot',
}

export const ORGANIZATION_ROLES = pick(['ADMIN', 'STAFF'], ROLES)

export const ORGANIZATION_ROLE_DISPLAY_NAMES = {
  [ORGANIZATION_ROLES.ADMIN]: 'Organization Admin',
  [ORGANIZATION_ROLES.STAFF]: 'Staff',
}

export const ENTITY_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
}

export const FLEETCODE_NETWORK_TYPES = {
  NBS: 'NBS',
}
