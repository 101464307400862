import { connect } from '@seedcloud/stateless'
import { useState } from 'react'

import { Button } from './Button'
import { ConfirmModal } from './ConfirmModal/ConfirmModal'

import { SUPPLIER_LABELS, SUPPLIER_LABEL_DISPLAY } from 'constants/supplier'
import { API_TYPES } from 'lib/http-client'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/company'

const {
  module: companyModule,
  selectors: { isSubmittingCompany$ },
} = createCompanyModule(API_TYPES.WEB)

const StatusBarStyled = styled.div(apply('flex'), {
  paddingInline: '1.6rem',
  paddingBlock: '1.866rem',
  fontSize: '15px',
  backgroundColor: '#FAF7E7',
  color: '#AC9100',
  '& p': apply('text-black mb-0'),
  '& > div:first-of-type': apply('flex-1'),
})

const SubmitButton = styled(Button)(apply('rounded-lg'), {
  backgroundColor: '#00326F',
  height: '100%',
  color: '#FFFFFF',
})

const getDescriptionByStatus = (status, company) => {
  switch (status) {
    case SUPPLIER_LABELS.ACTIVE:
      return `You have partnership with ${company}`
    case SUPPLIER_LABELS.UPDATED_DETAILS:
      return `Thank you for updating your company details. Please await our approval`
    case SUPPLIER_LABELS.PENDING_ACTIVATION:
      return 'Complete company details, aviation details, and pilot categories to activate your company'
    case SUPPLIER_LABELS.INVITED:
      return 'Complete company details, aviation details, and pilot categories to activate your company'
    default:
      return 'Thank you for setting up your company / user account. Once we approve your partnerships then we will ask you to complete your service profile'
  }
}

const getSubmitTextByStatus = (status) =>
  [SUPPLIER_LABELS.PENDING_ACTIVATION, SUPPLIER_LABELS.INVITED].includes(status)
    ? 'Submit Details '
    : 'Update Details'

const getModalTextByStatus = (status) =>
  status === SUPPLIER_LABELS.PENDING_ACTIVATION
    ? 'Are you sure you want to submit your company information?'
    : 'Are you sure you want to update your company information?'

const StatusBar = ({ status, company, isSubmittingCompany }) => {
  const [showModal, setShowModal] = useState(false)

  const shouldShowExtra = [
    SUPPLIER_LABELS.PENDING_ACTIVATION,
    SUPPLIER_LABELS.INVITED,
    SUPPLIER_LABELS.ACTIVE,
  ].includes(status)

  const handleSubmit = async () => {
    await companyModule.submitCompany(null, status)
    setShowModal(false)
  }

  return (
    <>
      <StatusBarStyled status={status}>
        <div>
          <b>Status :</b> {SUPPLIER_LABEL_DISPLAY[status]}
          <p>{getDescriptionByStatus(status, company)}.</p>
        </div>
        {shouldShowExtra ? (
          <div>
            <SubmitButton onClick={() => setShowModal(true)}>
              {getSubmitTextByStatus(status)}
            </SubmitButton>
          </div>
        ) : null}
      </StatusBarStyled>

      <ConfirmModal
        title={getSubmitTextByStatus(status)}
        subtitle={getModalTextByStatus(status)}
        isOpen={showModal}
        confirmLoadingText="Submitting..."
        loading={isSubmittingCompany}
        closeModal={() => setShowModal(false)}
        onConfirm={handleSubmit}
      />
    </>
  )
}

const ConnectedStatusBar = connect(() => ({
  isSubmittingCompany: isSubmittingCompany$,
}))(StatusBar)

export { ConnectedStatusBar as StatusBarWeb }
