import { useFormikContext as useFormContext } from 'formik'

import { Avatar } from 'components/common/Avatar'
import { ImageUpload } from 'components/common/ImageUpload'
import { FieldGroup, Row, TextField, SelectField } from 'components/company/common'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

function InvitePilotForm({ pilotCategoriesList, values, setAvatarFile }) {
  const { setFieldValue } = useFormContext()

  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${description} ${formatCurrency(rate)} / hr`,
  }))

  return (
    <FieldGroup title="Invite New Pilot">
      <Details>
        <Column style={apply('items-center w-10 mr-6', { flex: 'none' })}>
          <ImageUpload
            name="avatar"
            ImageComponent={Avatar}
            imageProps={{
              size: 96,
              placeholderSize: 80,
              style: apply('m-0'),
            }}
            onUpload={setAvatarFile}
          />
        </Column>
        <Column>
          <Row>
            <TextField
              value={values.firstName}
              name="firstName"
              type="text"
              label="First Name"
              required
            />
            <TextField
              value={values.lastName}
              name="lastName"
              type="text"
              label="Last Name"
              required
            />
          </Row>
          <Row>
            <TextField
              value={values.phoneNumber}
              name="phoneNumber"
              type="text"
              label="Phone Number"
              required
            />
            <TextField
              value={values.email}
              name="email"
              type="text"
              label="Email"
              required
            />
          </Row>
          <Row>
            <SelectField
              name="pilotCategoryId"
              label="Pilot Category"
              required
              emptyOption={{
                id: undefined,
                value: undefined,
              }}
              onChange={({ id }) => {
                setFieldValue('pilotCategoryId', id)
              }}
              options={pilotCategoryOptions}
              placeholder="Select Pilot Category"
            />
          </Row>
          <Row>
            <TextField
              disabled={true}
              value={values.companyName}
              name="companyName"
              type="text"
              label="Company Name"
            />
          </Row>
        </Column>
      </Details>
    </FieldGroup>
  )
}

export { InvitePilotForm }
