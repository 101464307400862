/* eslint-disable complexity */

import { pickThenRename, path, prepend } from '@seedcloud/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { FolderPanel } from '../documents/FolderPanel'
import { UploadInstructionModal } from '../documents/UploadInstructionModal'
import { UploadPanel } from '../documents/UploadPanel'
import { JobDocuments } from '../JobDocuments'

import { CompanyModal } from './CompanyModal'
import { PreviewModal } from './PreviewModal'

import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { Button } from 'components/common/Button'
import { useJobs } from 'components/common/context/JobContext'
import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { PriceBlock } from 'components/common/PriceBlock'
import { SelectField } from 'components/common/SelectField'
import { Title } from 'components/common/Tabs/ui'
import { TextAreaField } from 'components/common/TextAreaField'
import { Toggle as ToggleBase } from 'components/common/Toggle'
import { AreaOfInterest, ComplianceSection } from 'components/jobs/common'
import { ComplianceCard } from 'components/jobs/common/ComplianceCard'
import { JobPublishNetwork } from 'components/jobs/common/JobPublishNetwork'
import { JOB_STATUSES } from 'constants/jobs'
import { PUBLISH_TYPES, PUBLISH_TYPES_DISPLAY_NAMES } from 'constants/publishType'
import { apply, styled } from 'lib/styled'
import toast from 'lib/toaster'

const ButtonLink = styled(Button)(apply('bg-transparent border-0 underline'), {
  color: '#00326F',
})
const Container = styled.div(apply('mb-12'))
const Header = styled.div(apply('flex flex-row pt-10 pb-6 font-bold'), {
  '&:first-of-type': apply('mt-0'),
  '&:last-of-type': apply('mb-0'),
  color: '#00326F',
})
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(
  apply('flex-1 flex flex-column'),
  ({ gap, flex, marginRight }) => ({
    gap: gap || 0,
    flex: flex || '',
    marginRight: marginRight || '',
  })
)

const Row = styled.div(
  apply('flex flex-row my-2 items-start', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

const Separator = styled.div(
  apply('mx-4', {
    borderLeft: '1px solid #ccc',
    height: '100%',
  })
)
const PricingToggleContainer = styled.div(apply('flex my-3'))
const PricingContainer = styled.div(apply(''))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const ErrorLabel = styled.label(apply('my-2 tracking-wide text-xs text-red'))
const Toggle = styled(ToggleBase)(apply('mr-2'))
const ToggleContainer = styled.div(apply('flex pr-8'))
const FeedbackButton = styled(Button)(
  apply('w-12 py-4 px-4 flex items-center justify-center')
)

function JobInfo() {
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false)
  const fileUploaderRef = useRef(null)

  const { id } = useParams()

  const {
    isAdmin,
    isNBS,
    isKeyContacts,
    productList,
    projectList,
    companyList,
    pilotList,
    pilotCategoriesList,
    supplierList,
    reference: jobReference,
    isEditing,
    jobStatus,
    onCreateProject,
    onFilterProduct,
    onUpdateJob,
    reference,
    showSubmitFeedbackActions,
    inspectedJob,
    inspectedFolder = {},
    jobFolderList,
    onConfirmJobDocument,
    showComplianceSection,
  } = useJobs()

  const {
    setFieldValue,
    values: {
      jobInfo: { ratings, feedback, publishedAt, ...jobInfo },
      documents: { uploadedByPilot },
    },
    errors,
  } = useFormContext()

  const {
    radius,
    location,
    commercialSettings,
    acceptedBy,
    rejectedBy,
    publishType,
    pricing,
    publishedBy,
    company,
    uploadCode,
  } = jobInfo

  const jobPublished = [
    JOB_STATUSES.PUBLISHED,
    JOB_STATUSES.ENGAGED,
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const isNetworkJobQueue =
    isNBS && jobStatus === JOB_STATUSES.QUEUE && publishType === PUBLISH_TYPES.NETWORK

  const acceptTermsConditionsError = path(['jobInfo', 'acceptTermsConditions'], errors)
  const { billable, paid, dataUpload } = commercialSettings
  const { timeMaterial, quote } = pricing

  const coordinates = location?.geometry?.coordinates

  const publishTypeOptions = Object.values(PUBLISH_TYPES)
    .map((publishType) => ({
      id: publishType,
      value: publishType,
      label: PUBLISH_TYPES_DISPLAY_NAMES[publishType],
    }))
    .filter(({ value }) => {
      if (!isNBS || jobPublished) return true
      return value !== PUBLISH_TYPES.NETWORK || isNetworkJobQueue
    })

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))
  const projectOptions = projectList.map(({ id, reference }) => ({
    id,
    value: reference,
    label: reference,
  }))

  const companyOptions = companyList.map(
    ({ id, company: { companyName, userId } }) => ({
      id,
      value: companyName,
      label: companyName,
      userId,
    })
  )

  const pilotOptions = prepend(
    { id: undefined, value: undefined, label: 'All Pilot' },
    pilotList.map(({ id, userDetails }) => {
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`
      return {
        id,
        value: fullName,
        label: fullName,
      }
    })
  )

  const formatCurrency = (value, ...opts) =>
    new Intl.NumberFormat('au-AU', {
      style: 'currency',
      currency: 'AUD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      ...opts,
    }).format(value)

  // eslint-disable-next-line no-unused-vars
  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${description} ${formatCurrency(rate)} / hr`,
  }))

  const supplierListOptions = prepend(
    { id: undefined, value: undefined, label: 'All Supplier' },
    supplierList.map(({ id, company: { companyName } }) => ({
      id,
      value: companyName,
      label: companyName,
    }))
  )

  const ratingsOptions = [
    { id: '1', value: '1', label: '1' },
    { id: '2', value: '2', label: '2' },
    { id: '3', value: '3', label: '3' },
    { id: '4', value: '4', label: '4' },
    { id: '5', value: '5', label: '5' },
  ]

  const jobEngaged = [
    JOB_STATUSES.ENGAGED,
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobDelivered = [
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobInReview = [
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobCompleted = [JOB_STATUSES.COMPLETED].includes(jobStatus)

  const disablePublishType =
    !isEditing || publishedAt !== 'Unspecified' || isNetworkJobQueue

  return (
    <Container>
      <Header>
        <Title id="info">Job Info</Title>
      </Header>

      <Details>
        <Column>
          <Row>
            <FormInput
              disabled
              name="jobInfo.reference"
              type="text"
              label="Reference"
            />

            <DateField
              disabled={true}
              name="jobInfo.createdAt"
              type="text"
              label="Created At"
              autoOk
            />
          </Row>

          <Label>Commercial settings</Label>
          <Row>
            <ToggleContainer>
              <Toggle
                onChange={(e) =>
                  setFieldValue('jobInfo.commercialSettings.billable', e.target.checked)
                }
                checked={billable}
                disabled={!isEditing}
              />
              <Label>Billable</Label>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                onChange={(e) =>
                  setFieldValue('jobInfo.commercialSettings.paid', e.target.checked)
                }
                checked={paid}
                disabled={!isEditing}
              />
              <Label>Paid</Label>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                onChange={(e) =>
                  setFieldValue(
                    'jobInfo.commercialSettings.dataUpload',
                    e.target.checked
                  )
                }
                checked={dataUpload}
                disabled={!isEditing}
              />
              <Label>Data Upload</Label>
            </ToggleContainer>
          </Row>
          <Row>
            <SelectField
              name="jobInfo.product.name"
              label="Product Name"
              isDisabled={!isEditing}
              onChange={({ id, value: name }) => {
                setFieldValue('jobInfo.product', { id, name })
              }}
              options={productOptions}
              placeholder="Search and Select Product"
              onInputChange={onFilterProduct}
              containerProps={{ style: apply('mr-3') }}
            />

            <DateField
              disabled={!isEditing}
              name="jobInfo.scheduledAt"
              type="text"
              label="Scheduled At"
              autoOk
            />
          </Row>

          {isAdmin ? (
            <>
              <Row>
                <SelectField
                  name="jobInfo.template"
                  label="Job Template Type"
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.template', selectedOption.value)
                  }}
                  options={[{ id: 'default', value: 'default', label: 'Default' }]}
                  placeholder="Select a template type"
                  containerProps={{ style: apply('mr-3') }}
                  isDisabled={!isEditing}
                  testId="job-template-type"
                />

                <SelectField
                  name="jobInfo.project.reference"
                  label="Project ID"
                  labelIcon={
                    <ExclamationCircleIcon
                      {...apply('h-1 w-1', {
                        stroke: '#334155',
                      })}
                      data-tip="Project ID is only needed before finalising a job"
                      data-type="info"
                    />
                  }
                  creatable
                  isDisabled={!isEditing}
                  emptyOption={{ id: undefined, reference: undefined }}
                  onChange={({ id, value: reference }, { action }) => {
                    onCreateProject(action, reference)
                    setFieldValue('jobInfo.project', { id, reference })
                  }}
                  options={projectOptions}
                />
              </Row>

              {/* TODO: Uncomment, when customer using dropdown is used */}
              {/* <Row>
                <SelectField
                  name="jobInfo.company.companyName"
                  label="Customer"
                  onChange={(selectedOption) => {
                    const customer = pickThenRename(['id', 'value', 'userId'], {
                      value: 'companyName',
                    })(selectedOption)
                    setFieldValue('jobInfo.company', customer)
                  }}
                  options={companyOptions}
                  placeholder="Select customer"
                  isDisabled={!isEditing}
                  testId="job-company"
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.company.userId.email"
                  label="Customer Email"
                  testId="job-company-email"
                  disabled
                />
                <FormInput
                  name="jobInfo.company.userId.phoneNumber"
                  label="Customer Phone Number"
                  testId="job-company-phone-number"
                  disabled
                />
              </Row> */}
              <Row>
                <FormInput
                  name="jobInfo.customer.companyName"
                  label="Customer Name"
                  testId="job-customer-name"
                  disabled={!isEditing}
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.customer.companyEmail"
                  label="Customer Email"
                  testId="job-customer-email"
                  disabled={!isEditing}
                />
                <FormInput
                  name="jobInfo.customer.companyNumber"
                  label="Customer Number"
                  testId="job-customer-phone"
                  disabled={!isEditing}
                />
              </Row>
            </>
          ) : null}

          <Row>
            <LocationField
              name="jobInfo.location"
              label="Location"
              disabled={!isEditing}
              containerProps={{ style: apply('mr-3', { flexGrow: 6 }) }}
            />
            <FormInput
              name="jobInfo.radius"
              label="Radius in meters"
              disabled={!isEditing}
            />
          </Row>

          <AreaOfInterest coordinates={coordinates} radius={radius} />

          {acceptedBy && isAdmin && (
            <Row>
              <FormInput
                disabled
                name="jobInfo.acceptedBy.fullName"
                type="text"
                label="Accepted By"
              />
              <DateField
                disabled
                name="jobInfo.acceptedAt"
                type="text"
                label="Accepted At"
                autoOk
              />
            </Row>
          )}

          {rejectedBy && isAdmin && (
            <>
              <Label style={apply('mt-4')}>
                Rejected by {rejectedBy.firstName} {rejectedBy.lastName}
              </Label>
              <TextAreaField
                name="jobInfo.rejectionReason"
                containerStyle={apply('w-full')}
                minRows={1}
                noLabel
              />
            </>
          )}

          {jobStatus !== JOB_STATUSES.NEW && (
            <>
              {isAdmin && (
                <Row>
                  <FormInput
                    disabled={!isEditing}
                    name="jobInfo.internalProjectId"
                    type="text"
                    label="Internal Project Id"
                  />
                </Row>
              )}

              <TextAreaField
                disabled={!isEditing}
                name="jobInfo.description"
                label="description"
                containerStyle={apply('mt-4 mr-4 w-full')}
                minRows={6}
              />
              {isAdmin || jobEngaged ? (
                <TextAreaField
                  disabled={!isEditing}
                  name="jobInfo.instructions"
                  label="Instructions"
                  containerStyle={apply('mt-4 mr-4 w-full')}
                  minRows={6}
                />
              ) : null}

              {/* we force the component to remount since the form resets on submit, clearing out the docs inside formik state */}
              {showComplianceSection ? (
                <ComplianceSection key={isEditing} disabled={!isEditing} />
              ) : null}

              <Row>
                <SelectField
                  name="jobInfo.publishType"
                  label="Publish Type"
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.publishType', selectedOption.value)
                  }}
                  options={publishTypeOptions}
                  placeholder="Select publish type"
                  containerProps={{ style: apply('mr-3') }}
                  isDisabled={disablePublishType}
                  testId="job-company"
                />
                {publishType === PUBLISH_TYPES.INTERNAL && (
                  <SelectField
                    name="jobInfo.selectedPilot.name"
                    label="Recommend Pilot"
                    emptyOption={{
                      id: undefined,
                      value: undefined,
                    }}
                    onChange={({ id, value: name }) => {
                      setFieldValue('jobInfo.selectedPilot', { id, name })
                    }}
                    options={pilotOptions}
                    placeholder="Select Pilot"
                    isDisabled={disablePublishType}
                  />
                )}
                {publishType === PUBLISH_TYPES.SUPPLIER && (
                  <SelectField
                    name="jobInfo.selectedSupplier.companyName"
                    label="Suppliers"
                    emptyOption={{
                      id: undefined,
                      value: undefined,
                    }}
                    onChange={({ id, value: companyName }) => {
                      setFieldValue('jobInfo.selectedSupplier', { id, companyName })
                    }}
                    options={supplierListOptions}
                    placeholder="Select Supplier"
                    isDisabled={disablePublishType}
                  />
                )}
                {/* We hide this until it's clear what to do with this on network job flow */}
                {/* {publishType === PUBLISH_TYPES.NETWORK && (
                  <SelectField
                    name="jobInfo.selectedPilotCategory.name"
                    label="Pilot Category"
                    emptyOption={{
                      id: undefined,
                      value: undefined,
                    }}
                    onChange={({ id, value: name }) => {
                      setFieldValue('jobInfo.selectedPilotCategory', { id, name })
                    }}
                    options={pilotCategoryOptions}
                    placeholder="Select Pilot Category"
                    isDisabled={disablePublishType}
                    testId="job-client-name"
                  />
                )} */}
              </Row>

              <JobPublishNetwork
                pilotOptions={pilotOptions}
                supplierListOptions={supplierListOptions}
                publishTypeOptions={publishTypeOptions}
                isDisabled={!isEditing || jobPublished}
              />

              <Row>
                <PricingContainer>
                  <Label>Job Pricing</Label>
                  <PricingToggleContainer>
                    <ToggleContainer>
                      <Toggle
                        onChange={(e) =>
                          setFieldValue(
                            'jobInfo.pricing.timeMaterial',
                            e.target.checked
                          )
                        }
                        checked={timeMaterial}
                        disabled={!isEditing}
                      />
                      <Label>Time & Material</Label>
                    </ToggleContainer>
                    <ToggleContainer>
                      <Toggle
                        onChange={(e) =>
                          setFieldValue('jobInfo.pricing.quote', e.target.checked)
                        }
                        checked={quote}
                        disabled={!isEditing}
                      />
                      <Label>Quote</Label>
                    </ToggleContainer>
                  </PricingToggleContainer>
                </PricingContainer>
                <FormInput
                  disabled={!isEditing}
                  name="jobInfo.pricing.budget"
                  type="text"
                  label="Budget"
                />
              </Row>

              {isAdmin ? (
                <>
                  {publishType === PUBLISH_TYPES.NETWORK ? (
                    <>
                      <ComplianceCard
                        id="jobInfo.acceptTermsConditions"
                        name="jobInfo.acceptTermsConditions"
                        title="Approve Terms & Conditions"
                        disabled={!isEditing}
                        style={apply('mt-3')}
                        complianceMessage={
                          <span>
                            By publishing this job, you acknowledge and agree to abide
                            by the following{' '}
                            <a
                              href="https://platform-assets.nearbysky.com/nbs_tc.pdf"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms & Conditions
                            </a>
                          </span>
                        }
                      />
                      {acceptTermsConditionsError && (
                        <ErrorLabel>{acceptTermsConditionsError}</ErrorLabel>
                      )}
                    </>
                  ) : null}
                  <Row>
                    <Button
                      type="button"
                      style={apply('my-4')}
                      onClick={() => setIsPreviewModalOpen(true)}
                    >
                      Preview
                    </Button>
                  </Row>
                </>
              ) : null}

              {publishedBy && (
                <Row>
                  <FormInput
                    disabled
                    name="jobInfo.publishedBy.fullName"
                    type="text"
                    label="Published By"
                  />
                  <DateField
                    disabled
                    name="jobInfo.publishedAt"
                    type="text"
                    label="Published At"
                    autoOk
                  />
                </Row>
              )}
            </>
          )}

          {jobEngaged && (
            <>
              <Header>
                <Title id="engaged-by">Engaged By</Title>
              </Header>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.engagedBy.fullName"
                  type="text"
                  label="Engaged By"
                />
                <FormInput
                  disabled
                  name="jobInfo.engagedBy.email"
                  type="text"
                  label="Email"
                />
              </Row>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.engagedBy.phoneNumber"
                  type="text"
                  label="Phone Number"
                />
                <DateField
                  disabled
                  name="jobInfo.engagedAt"
                  type="text"
                  label="Engaged At"
                  autoOk
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.pilotCompany.company.companyName"
                  label="Company"
                  onChange={(selectedOption) => {
                    setFieldValue(
                      'jobInfo.company',
                      pickThenRename(['id', 'value', 'email', 'phoneNumber'], {
                        value: 'companyName',
                      })(selectedOption)
                    )
                  }}
                  options={companyOptions}
                  placeholder="Select customer"
                  isDisabled
                  testId="job-company"
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.pilotCompany.company.email"
                  label="Company Email"
                  testId="job-company-email"
                  disabled
                />
                <FormInput
                  name="jobInfo.pilotCompany.company.phoneNumber"
                  label="Company Phone Number"
                  testId="job-company-phone-number"
                  disabled
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.pilotCompany.company.type"
                  label="Company Type"
                  testId="job-company-type"
                  disabled
                />
              </Row>
              {company && isAdmin ? (
                <Button
                  type="button"
                  style={apply('my-4')}
                  onClick={() => setIsCompanyModalOpen(true)}
                >
                  View Company
                </Button>
              ) : null}
            </>
          )}

          {jobDelivered && (
            <>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.uploadCode"
                  type="text"
                  label="upload code"
                />
              </Row>
              {!isKeyContacts ? (
                <>
                  <Header>
                    <Title id="assets">Job Assets</Title>
                  </Header>
                  <Row>
                    <Column flex="0 0 24%">
                      <FolderPanel />
                    </Column>
                    <Separator />
                    <Column>
                      <Row style={apply('justify-between')}>
                        <h4 style={apply('font-extrabold mb-8')}>Upload Assets</h4>
                        <ButtonLink
                          type="button"
                          onClick={() => setIsInstructionModalOpen(true)}
                        >
                          How to upload files?
                        </ButtonLink>
                      </Row>
                      <UploadPanel
                        key={inspectedFolder.fullPath}
                        jobId={id}
                        path={inspectedFolder.id}
                        reference={reference}
                        uploadCode={uploadCode}
                        files={uploadedByPilot}
                        ref={fileUploaderRef}
                        onConfirmJobDocument={onConfirmJobDocument}
                      />
                      <JobDocuments
                        type="pilot"
                        jobReference={jobReference}
                        folderList={jobFolderList}
                      />
                    </Column>
                  </Row>
                </>
              ) : null}

              {isKeyContacts ? (
                <JobDocuments type="pilot" jobReference={jobReference} />
              ) : null}

              <Header>
                <Title id="timesheet">Timesheet</Title>
              </Header>
              <Row>
                <FormInput
                  name="jobInfo.equipmentUsed"
                  type="text"
                  label="Equipment Used"
                  disabled={!isEditing}
                />
                <FormInput
                  name="jobInfo.equipmentFee"
                  type="text"
                  label="Equipment Fee"
                  disabled={!isEditing}
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.calloutFee"
                  type="text"
                  label="Callout Fee"
                  disabled={!isEditing}
                />
                <FormInput
                  name="jobInfo.marketFee"
                  type="text"
                  label="Market Fee"
                  disabled={!isEditing}
                />
              </Row>
              <PriceBlock isEditing={isEditing} style={apply('my-4')} />
            </>
          )}
          {jobInReview && (
            <>
              <Header>
                <Title id="review">Staff Review</Title>
              </Header>
              <JobDocuments type="staff" jobReference={jobReference} />
            </>
          )}
          {jobCompleted && (
            <>
              <Header>
                <Title id="feedback">Rating & Feedback</Title>
              </Header>
              <Row>
                <SelectField
                  name="jobInfo.ratings.communication"
                  label="Pilot Communication"
                  isDisabled={!isAdmin}
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.ratings.communication', selectedOption.value)
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-communication"
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.ratings.mediaAndPhotoQuality"
                  label="Media & Photo Quality"
                  isDisabled={!isAdmin}
                  onChange={(selectedOption) => {
                    setFieldValue(
                      'jobInfo.ratings.mediaAndPhotoQuality',
                      selectedOption.value
                    )
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-mediaAndPhotoQuality"
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.ratings.overall"
                  label="Job Satisfaction"
                  isDisabled={!isAdmin}
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.ratings.overall', selectedOption.value)
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-overall"
                />
              </Row>
              <Row>
                <TextAreaField
                  name="jobInfo.feedback"
                  label="Feedback"
                  disabled={!isAdmin}
                  containerStyle={apply('mt-4 mr-4 w-full')}
                  minRows={6}
                />
              </Row>
              {JOB_STATUSES.COMPLETED && isAdmin && showSubmitFeedbackActions ? (
                <Row>
                  <FeedbackButton
                    style={apply('mr-4')}
                    type="button"
                    onClick={async () => {
                      await onUpdateJob(inspectedJob.id, {
                        jobInfo: {
                          ratings,
                          feedback,
                        },
                      })
                      toast.success('Feedback submitted')
                    }}
                  >
                    Submit feedback
                  </FeedbackButton>
                </Row>
              ) : null}
            </>
          )}
        </Column>
      </Details>

      <ReactTooltip />

      {company && isAdmin ? (
        <CompanyModal
          isOpen={isCompanyModalOpen}
          setIsOpen={setIsCompanyModalOpen}
          companyId={company.id}
        />
      ) : null}
      <PreviewModal
        isOpen={isPreviewModalOpen}
        setIsOpen={setIsPreviewModalOpen}
        jobInfo={jobInfo}
      />
      <UploadInstructionModal
        isOpen={isInstructionModalOpen}
        closeModal={() => setIsInstructionModalOpen(false)}
      />
    </Container>
  )
}

export { JobInfo }
