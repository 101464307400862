import { isNotNil, prop, defaultTo, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { format } from 'date-fns'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const getFormattedDateOrUndefined = (date) => {
  const result = isNotNilOrEmpty(date)
    ? format(new Date(date), 'yyyy-MM-dd')
    : undefined

  return result
}

const companyEntity = new schema.Entity('company')
const pilotEntity = new schema.Entity('pilots')

const CompanyService = {
  async list({ query = '', type, limit = 1000, next, isNBS = '' }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        isNBS,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    if (isNotNil(type)) {
      config.searchParams.type = type
    }

    const { items, paging } = await adminApi.get('companies', config).json()

    const normalized = normalize(items, [companyEntity])
    const entities = defaultTo({}, prop('company', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async create(body) {
    const config = {
      json: body,
    }

    return adminApi.post('companies', config).json()
  },

  async read(id) {
    return adminApi.get(`companies/${id}`).json()
  },

  async update(id, { insuranceExpiredAt, ...otherData }) {
    const config = {
      json: {
        insuranceExpiredAt: getFormattedDateOrUndefined(insuranceExpiredAt),
        ...otherData,
      },
    }

    return adminApi.patch(`companies/${id}`, config).json()
  },

  async listPilots({ id, query = '', limit = 1000, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await adminApi
      .get(`companies/${id}/pilots`, config)
      .json()

    const normalized = normalize(items, [pilotEntity])
    const entities = defaultTo({}, prop('pilots', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async add(id) {
    return adminApi.post(`companies/${id}/add`)
  },

  async reinvite(id) {
    return adminApi.post(`companies/${id}/reinvite`)
  },

  async invitePilot({ companyId, userDetails }) {
    const config = {
      json: userDetails,
    }

    return adminApi.post(`companies/${companyId}/pilots`, config).json()
  },
}

export { CompanyService }
