import { ResendInvite } from './ResendInvite'

import { styled, apply } from 'lib/styled'

const Title = styled.h2(apply('my-6 text-xl font-light'))

function CompanyActionsSection({ id, isActive }) {
  return isActive ? null : (
    <>
      <Title>Actions</Title>
      <ResendInvite id={id} />
    </>
  )
}

export { CompanyActionsSection }
