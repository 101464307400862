/* eslint-disable camelcase */
import { path, prop } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useState, useLayoutEffect, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { usePilotCompany } from '../common/hooks/usePilotCompany'

import { JobDetails } from './JobDetails'
import getFormInitialValues from './utils/getFormInitialValues'

import { JobsProvider } from 'components/common/context/JobContext'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { PUBLISH_TYPES } from 'constants/publishType'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { SUPPLIER_LABELS } from 'constants/supplier'
import { getUserOrganizationId } from 'lib/getRole'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule as createOrganizationModule } from 'modules/company'
import { createDocumentModule } from 'modules/document'
import {
  inspectedJob$,
  isFinalizingJob$,
  isInspectingJobs$,
  jobModule,
} from 'modules/job'
import { createCompanyModule } from 'modules/legacy-company'
import { createOrganizationModule as createWebOrganizationModule } from 'modules/organization'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { productModule, productList$, filterQuery$ } from 'modules/product'
import { projectList$, projectModule } from 'modules/project'
import { staffModule, staffList$ } from 'modules/staff'
import { supplierList$, supplierModule } from 'modules/supplier'

const {
  module: documentModule,
  selectors: {
    isOperatingFile$,
    jobDocumentList$,
    jobFolderList$,
    inspectedFolder$,
    organizationDocumentList$,
  },
} = createDocumentModule()

const {
  module: companyModule,
  selectors: { companyList$ },
} = createCompanyModule()

const {
  module: organizationModule,
  selectors: { isNBS$ },
} = createOrganizationModule()

const {
  module: pilotModule,
  selectors: { pilotList$ },
} = createPilotModule()

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule()

const {
  module: pilotCompanyModule,
  selectors: { inspectedOrganization$ },
} = createWebOrganizationModule(API_TYPES.WEB)

const ConnectedJobDetailsContainer = connect(() => ({
  inspectedJob: inspectedJob$,
  isInspectingJobs: isInspectingJobs$,
  productList: productList$,
  companyList: companyList$,
  pilotList: pilotList$,
  pilotCategoriesList: pilotCategoriesList$,
  projectList: projectList$,
  supplierList: supplierList$,
  filterQuery: filterQuery$,
  staffList: staffList$,
  isFinalizingJob: isFinalizingJob$,
  jobDocumentList: jobDocumentList$,
  organizationDocumentList: organizationDocumentList$,
  jobFolderList: jobFolderList$,
  inspectedFolder: inspectedFolder$,
  isOperatingFile: isOperatingFile$,
  isNBS: isNBS$,
  pilotCompany: inspectedOrganization$,
}))(JobDetailsAdminContainer)

// eslint-disable-next-line complexity
function JobDetailsAdminContainer({
  inspectedJob = {},
  isInspectingJobs,
  jobDocumentList,
  organizationDocumentList,
  jobFolderList,
  inspectedFolder,
  isOperatingFile,
  productList,
  companyList,
  pilotList,
  pilotCategoriesList,
  projectList,
  supplierList,
  staffList,
  isFinalizingJob,
  isNBS,
  pilotCompany,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const { id } = useParams()
  const { role, user } = useIdentity()

  useLayoutEffect(() => {
    jobModule.inspectJob(id)
  }, [])

  usePilotCompany(pilotCompanyModule, inspectedJob, 'engagedBy')

  useEffect(() => {
    const scroll = document.querySelector('#scroll')
    if (scroll) scroll.scrollTop = 0
  }, [inspectedJob?.status])

  const { status: jobStatus, reference, publishType } = inspectedJob

  const { jobInfo, documents, contactInfo } = getFormInitialValues(
    inspectedJob,
    jobDocumentList,
    organizationDocumentList,
    pilotCompany
  )

  const isNetworkPublishType = publishType === PUBLISH_TYPES.NETWORK

  let showEditingTab = ![
    JOB_STATUSES.NEW,
    JOB_STATUSES.CANCELLED,
    JOB_STATUSES.COMPLETED,
  ].includes(jobStatus)

  if (isNetworkPublishType) {
    if (isNBS) {
      showEditingTab = [
        JOB_STATUSES.QUEUE,
        JOB_STATUSES.PUBLISHED,
        JOB_STATUSES.ENGAGED,
        JOB_STATUSES.DELIVERED,
        JOB_STATUSES.IN_REVIEW,
      ].includes(jobStatus)
    } else {
      showEditingTab = [
        JOB_STATUSES.QUEUE,
        JOB_STATUSES.IN_REVIEW_NETWORK,
        JOB_STATUSES.COMPLETED,
        JOB_STATUSES.CANCELLED,
      ].includes(jobStatus)
    }
  }

  const initialValues = {
    status: jobStatus,
    jobInfo,
    contactInfo,
    documents,
  }

  const publishedAt = path(['jobInfo', 'publishedAt'], initialValues)

  const showPublishToActions = isNBS || (!isNBS && publishedAt === 'Unspecified')

  const showSubmitFeedbackActions = !isNBS || !isNetworkPublishType

  const loading = !inspectedJob || id !== prop('id', inspectedJob) || isInspectingJobs

  const currentOrgId = getUserOrganizationId(user)

  // JobInfo
  useLayoutEffect(() => {
    productModule.fetchProducts(null)
    projectModule.fetchProjects(null)
    companyModule.fetchCompanies(null)
    organizationModule.inspectCompany()
    pilotModule.filterPilots(null, { companyId: currentOrgId })
    pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    pilotCategoryModule.fetchPilotCategories(null, {})
    supplierModule.filterSuppliers(null, { invitationStatus: SUPPLIER_LABELS.ACTIVE })

    // we use filterJobDocuments instead of fetchJobDocuments to be explicit about fetching ALL job document types.
    documentModule.inspectFolder(id)
    documentModule.filterJobDocuments(id, {
      documentTypes: [
        DOCUMENT_TYPES.JOB_TERMS,
        DOCUMENT_TYPES.JOB_JSEA,
        DOCUMENT_TYPES.JOB_CASA,
        DOCUMENT_TYPES.JOB_ASSET,
      ],
    })

    return () => {
      documentModule.reset()
      pilotModule.reset()
      pilotCategoryModule.resetPagination()
      supplierModule.reset()
    }
  }, [])

  const filteredSupplierList = supplierList.filter(
    (supplier) => supplier.id !== currentOrgId
  )

  const onCreateProject = (action, reference) => {
    if (action === 'create-option') {
      projectModule.createProject(null, reference)
    }
  }

  const onFilterProduct = productModule.filterProducts

  // ContactInfo
  const editableClient = role !== ORGANIZATION_ROLES.STAFF

  useLayoutEffect(() => {
    if (editableClient) {
      staffModule.fetchStaff(null)
    }
  }, [editableClient])

  // Actions
  const onAcceptJob = async (id) => {
    await jobModule.acceptJob(id)
    setIsEditing(false)
  }

  const onRejectJob = async (id, payload) => {
    await jobModule.rejectJob(id, payload)
    setIsEditing(false)
  }

  const onPublishJob = async (id, payload) => {
    await jobModule.publishJob(id, payload)
    setIsEditing(false)
  }

  const onFinalizeJob = async (payload) => {
    await jobModule.finalizeJob(null, payload)
    setIsEditing(false)
  }

  const onUpdateJob = async (id, payload, updateSelectedFields) => {
    await jobModule.updateJob(id, payload, updateSelectedFields)
    setIsEditing(false)
  }

  const onUploadJobDocument = async (file, documentType) => {
    await documentModule.uploadJobDocument(null, {
      file,
      documentType,
    })
  }

  const onDownloadJobDocument = async (id, fileSize) =>
    documentModule.downloadJobDocument(id, { multipart: true, fileSize })

  const onDeleteJobDocument = documentModule.deleteJobDocument
  const onConfirmJobDocument = documentModule.confirmJobDocumentUpload
  const onInspectFolder = documentModule.inspectFolder
  const onCreateFolder = documentModule.createFolder

  return (
    <JobsProvider
      value={{
        isEditing,
        setIsEditing,
        jobStatus,
        showSubmitFeedbackActions,
        showPublishToActions,
        showEditingTab,
        showComplianceSection: true,
        initialValues,
        reference,
        loading,
        inspectedJob,
        isFinalizingJob,
        isOperatingFile,
        organizationDocumentList,
        jobDocumentList,
        jobFolderList,
        inspectedFolder,
        isAdmin: true,
        isKeyContacts: false,
        isNBS,
        // JobInfo props
        productList,
        projectList,
        companyList,
        pilotList,
        pilotCategoriesList,
        supplierList: filteredSupplierList,
        onCreateProject,
        onFilterProduct,
        // ContactInfo props
        editableClient,
        staffList,
        // Actions
        onAcceptJob,
        onRejectJob,
        onPublishJob,
        onFinalizeJob,
        onUpdateJob,
        onUploadJobDocument,
        onDownloadJobDocument,
        onDeleteJobDocument,
        onConfirmJobDocument,
        onInspectFolder,
        onCreateFolder,
      }}
    >
      <JobDetails />
    </JobsProvider>
  )
}

export { ConnectedJobDetailsContainer }
