import { useEffect } from 'react'

import { ConnectedJobTable as ConnectedJobTableAdmin } from './JobTableAdminContainer'
import { ConnectedJobTable } from './JobTableContainer'

import { useIdentity } from 'lib/solta-id-react'
import { jobModule } from 'modules/job'

const JobTableGateway = () => {
  const { isAdmin } = useIdentity()

  useEffect(() => {
    if (isAdmin) {
      jobModule.setSelectedTab(null, 'new')
    } else {
      jobModule.setSelectedTab(null, 'published')
    }
  })

  return isAdmin ? <ConnectedJobTableAdmin /> : <ConnectedJobTable />
}

export { JobTableGateway as JobTable }
