/* eslint-disable complexity */
import { AbilityBuilder, Ability } from '@casl/ability'

import { RESOURCES } from 'constants/resources'
import { ROLES } from 'constants/roles'

const {
  PILOT_APPLICATION,
  PILOT_DETAILS,
  JOB,
  PRODUCT,
  FILE,
  STAFF,
  COMPANY,
  MY_COMPANY,
  SUPPLIER,
  CLAIM_COMPANY,
  MY_PILOT_INFO,
} = RESOURCES

// 'manage' equals 'CRUD'
export function defineAbilityFor(role) {
  const { can, cannot, build } = new AbilityBuilder(Ability)

  switch (role) {
    case ROLES.STAFF:
      can('read', PILOT_APPLICATION)
      can('read', PILOT_DETAILS)
      can(['read', 'create', 'update'], JOB)
      can('read', PRODUCT)
      can('read', FILE)
      break
    case ROLES.ADMIN:
      can(['read', 'update', 'delete'], PILOT_APPLICATION)
      can(['read', 'update', 'delete'], PILOT_DETAILS)
      can('manage', MY_COMPANY)
      can('manage', JOB)
      can('manage', PRODUCT)
      can('manage', FILE)
      can('manage', STAFF)
      can('manage', SUPPLIER)
      can('manage', COMPANY)
      break
    // Non Admin Abilities
    case ROLES.COMMERCIAL:
      can(['read'], JOB)
      can('manage', FILE)
      can('manage', MY_COMPANY)
      can('manage', CLAIM_COMPANY)
      can(['read', 'create', 'update'], PILOT_DETAILS)
      break
    case ROLES.BILLING:
      can(['read'], JOB)
      can('manage', FILE)
      can('manage', MY_COMPANY)
      can('manage', CLAIM_COMPANY)
      can(['read', 'create', 'update'], PILOT_DETAILS)
      break
    case ROLES.KEY_CONTACT:
      can(['read'], JOB)
      can('manage', FILE)
      can('manage', MY_COMPANY)
      can('manage', CLAIM_COMPANY)
      can(['read', 'create', 'update'], PILOT_DETAILS)
      break
    case ROLES.HERO_PILOT:
      can(['read', 'create', 'update'], JOB)
      can('manage', MY_PILOT_INFO)
      can('manage', FILE)
      can('manage', MY_COMPANY)
      can('manage', CLAIM_COMPANY)
      can(['read', 'create', 'update'], PILOT_DETAILS)
      break
    case ROLES.PILOT:
      can(['read'], JOB)
      can('manage', MY_PILOT_INFO)
      break
    case ROLES.ONBOARDING_PILOT:
      can(['read', 'create', 'update'], JOB)
      can('manage', FILE)
      can('manage', COMPANY)
      can('manage', CLAIM_COMPANY)
      break
    default:
      cannot('manage', 'all')
  }

  return build()
}
