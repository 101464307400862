/* eslint-disable complexity */

import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'

import { Button as ButtonBase } from 'components/common/Button'
import { useJobs } from 'components/common/context/JobContext'
import { Spinner } from 'components/common/Spinner'
import { TextAreaField } from 'components/common/TextAreaField'
import { WithComplianceCheckbox } from 'components/jobs/common/WithComplianceCheckbox'
import { JOB_STATUSES } from 'constants/jobs'
import { PUBLISH_TYPES } from 'constants/publishType'
import { ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { apply, styled } from 'lib/styled'
import toast from 'lib/toaster'

const btnStyle = apply('w-12 py-4 px-4 flex items-center justify-center')

const Button = styled(ButtonBase)(btnStyle)

const ActionContainer = styled.div(apply('my-8 flex'))
const ActionNoMarginContainer = styled.div(apply('flex flex-grow-1'))
const AcceptButton = styled(Button)(
  apply('mr-4 border-green-light', {
    backgroundColor: '#009F4D',
  }),
  ({ disabled }) =>
    disabled
      ? apply('bg-grey-lighter border-grey-light', { cursor: 'not-allowed' })
      : {}
)

const RejectButton = styled(Button)(
  apply('border-red-light', {
    backgroundColor: '#D42C16',
  }),
  ({ disabled }) =>
    disabled
      ? apply('bg-grey-lighter border-grey-light', { cursor: 'not-allowed' })
      : {}
)

const ComplianceMessage = styled.div(apply('flex'), { gap: '.5rem' })

const toastStatusChanges = (status) =>
  toast.success(`Job successfully moved to state ${status}`)

function Actions() {
  const { role } = useIdentity()
  const { values } = useFormContext()

  const {
    inspectedJob,
    onAcceptJob,
    onRejectJob,
    onPublishJob,
    onFinalizeJob,
    onUpdateJob,
    onEngagedJob,
    isAdmin,
    showSubmitFeedbackActions,
    showPublishToActions,
    isKeyContacts,
    onDownloadOrganizationTnC = () => {},
    shouldDisableCompliance = false,
    isNBS,
  } = useJobs()

  const {
    jobInfo: {
      rejectionReason,
      acceptTermsConditions,
      publishType,
      startedAt,
      finishedAt,
      equipmentUsed,
      equipmentFee,
      calloutFee,
      marketFee,
    },
    documents: { uploadedByPilot },
  } = values

  if (isKeyContacts || role === ROLES.STAFF) return null

  switch (inspectedJob.status) {
    case JOB_STATUSES.NEW:
      return (
        <WithComplianceCheckbox
          title="Approve PaaS Agreements"
          key="compliance-new"
          hideCompliance
          complianceMessage={
            <ComplianceMessage>
              By accepting this job, you acknowledge and agree to abide by the following{' '}
              <a
                className="tnc-link"
                href="https://platform-assets.nearbysky.com/nbs_tc.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PaaS agreements
              </a>
            </ComplianceMessage>
          }
          onSubmit={() =>
            onAcceptJob(inspectedJob.id).then(() => toastStatusChanges('queue'))
          }
        >
          {({ submit }) => (
            <ActionNoMarginContainer>
              {isNilOrEmpty(inspectedJob.acceptedBy) &&
                isNilOrEmpty(inspectedJob.rejectedBy) && (
                  <>
                    <AcceptButton type="button" onClick={submit}>
                      Accept
                    </AcceptButton>
                    <RejectButton
                      type="button"
                      onClick={() =>
                        onRejectJob(inspectedJob.id, { rejectionReason }).then(() =>
                          toastStatusChanges('cancelled')
                        )
                      }
                    >
                      Reject
                    </RejectButton>
                    <TextAreaField
                      name="jobInfo.rejectionReason"
                      containerStyle={apply('mx-4 w-full')}
                      minRows={1}
                      defaultValue="Please specify your reason for rejecting this job..."
                      noLabel
                      resize={'none'}
                    />
                  </>
                )}
            </ActionNoMarginContainer>
          )}
        </WithComplianceCheckbox>
      )
    case JOB_STATUSES.QUEUE: {
      const getButtonText = (publishType, isNBS) => {
        if (publishType === PUBLISH_TYPES.NETWORK) {
          return isNBS ? 'Publish to Network' : 'Proceed to Network'
        }
        return 'Publish'
      }
      return (
        showPublishToActions && (
          <ActionContainer>
            <Button
              type="button"
              style={apply('mr-4')}
              onClick={() => {
                if (
                  isNilOrEmpty(acceptTermsConditions) &&
                  publishType === PUBLISH_TYPES.NETWORK
                ) {
                  toast.error('The terms and conditions must be accepted.')
                } else {
                  onPublishJob(inspectedJob.id, {
                    jobInfo: values.jobInfo,
                    contactInfo: values.contactInfo,
                  }).then(() => toastStatusChanges('published'))
                }
              }}
            >
              {getButtonText(publishType, isNBS)}
            </Button>
          </ActionContainer>
        )
      )
    }
    case JOB_STATUSES.PUBLISHED:
      return !isAdmin ? (
        <WithComplianceCheckbox
          key="compliance-published"
          title="Approve Client Hub Agreements"
          disabled={shouldDisableCompliance}
          hideCompliance
          complianceMessage={
            <ComplianceMessage>
              By engaging this job, you acknowledge and agree to abide by the following{' '}
              <span className="tnc-link" onClick={onDownloadOrganizationTnC}>
                Client Hub agreements
              </span>
              {shouldDisableCompliance && <Spinner size={12} thickness={3} />}
            </ComplianceMessage>
          }
          onSubmit={() => onEngagedJob().then(() => toastStatusChanges('engaged'))}
        >
          {({ submit }) => (
            <ActionNoMarginContainer>
              <Button type="button" onClick={submit}>
                Engage Job
              </Button>
            </ActionNoMarginContainer>
          )}
        </WithComplianceCheckbox>
      ) : null

    case JOB_STATUSES.ENGAGED:
      return !isAdmin ? (
        <ActionContainer>
          <Button
            type="button"
            onClick={() =>
              onUpdateJob(inspectedJob.id, {
                status: JOB_STATUSES.DELIVERED,
                jobInfo: {},
                contactInfo: {},
                fetchEngaged: true,
              }).then(() => toastStatusChanges('delivered'))
            }
          >
            Deliver Job
          </Button>
        </ActionContainer>
      ) : null
    case JOB_STATUSES.DELIVERED:
      return !isAdmin ? (
        <WithComplianceCheckbox
          key="compliance-delivered"
          title="Approve PaaS SLA Agreements"
          hideCompliance
          complianceMessage={
            <ComplianceMessage>
              By delivering this job, you acknowledge and agree to abide by the
              following{' '}
              <a
                className="tnc-link"
                href="https://platform-assets.nearbysky.com/nbs_tc.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PaaS SLA agreements
              </a>
            </ComplianceMessage>
          }
          onSubmit={async () => {
            if (uploadedByPilot.length === 0) {
              toast.error(
                'Please upload the deliverables in Job Assets before submit for review'
              )
              return
            }
            await onUpdateJob(inspectedJob.id, {
              status: JOB_STATUSES.IN_REVIEW,
              jobInfo: {
                startedAt,
                finishedAt,
                equipmentUsed,
                equipmentFee,
                calloutFee,
                marketFee,
              },
              fetchEngaged: true,
            }).then(() => toastStatusChanges('in review'))
          }}
        >
          {({ submit }) => (
            <ActionNoMarginContainer>
              <Button type="button" onClick={submit}>
                Submit for review
              </Button>
            </ActionNoMarginContainer>
          )}
        </WithComplianceCheckbox>
      ) : null
    case JOB_STATUSES.IN_REVIEW_NETWORK:
    case JOB_STATUSES.IN_REVIEW:
      if (
        isNBS &&
        publishType === PUBLISH_TYPES.NETWORK &&
        inspectedJob.status === JOB_STATUSES.IN_REVIEW_NETWORK
      ) {
        return null
      }
      return isAdmin ? (
        <ActionContainer>
          <Button
            style={apply('mr-4')}
            type="button"
            onClick={() =>
              onFinalizeJob({ inviteOnly: false }).then(() =>
                toastStatusChanges('completed')
              )
            }
          >
            Complete
          </Button>
          <RejectButton
            type="button"
            onClick={() =>
              onUpdateJob(inspectedJob.id, {
                status: JOB_STATUSES.DISPUTED,
                jobInfo: {},
                contactInfo: {},
              }).then(() => toastStatusChanges('disputed'))
            }
          >
            Dispute
          </RejectButton>
        </ActionContainer>
      ) : null
    case JOB_STATUSES.COMPLETED:
      return isAdmin && showSubmitFeedbackActions ? (
        <ActionContainer>
          <Button
            style={apply('mr-4')}
            type="button"
            onClick={() => onFinalizeJob(null, { inviteOnly: true })}
          >
            Send email
          </Button>
        </ActionContainer>
      ) : null
    default:
      return null
  }
}

export { Actions }
