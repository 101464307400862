import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { BillingContact } from '../common/Fields/BllingContact'
import { CommercialContact } from '../common/Fields/CommercialContact'
import { Details } from '../common/Fields/Details'
import { HeroPilotContact } from '../common/Fields/HeroPilotContact'
import { SupplierLayout } from '../common/SupplierLayout'

import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { FormAction, FormLayoutProvider } from 'components/company/common'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { isPublishing$, supplierModule } from 'modules/supplier'

const { object, string } = Validator
const requiredString = (label) => string().required().label(label)
const optionalString = (label) => string().optional().label(label)

const validationSchema = object({
  companyName: requiredString('Supplier Name'),
  abn: requiredString('ABN').matches(/^\d+$/, 'ABN should only contain number'),
  phoneNumber: requiredString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64213112)'
  ),
  website: requiredString('Supplier Website').url(
    'Please input full url (e.g https://example.com)'
  ),
  address: requiredString('Supplier Address'),
  supplierType: requiredString('Supplier Type'),
  commercial: object().shape({
    firstName: requiredString('Commercial Contact First Name'),
    lastName: requiredString('Commercial Contact Last Name'),
    phoneNumber: requiredString('Commercial Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Commercial Email').email('Not a valid email address'),
  }),
  billing: object().shape({
    firstName: requiredString('Billing Contact First Name'),
    lastName: requiredString('Billing Contact Last Name'),
    phoneNumber: requiredString('Billing Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Billing Email').email('Not a valid email address'),
  }),
  heroPilot: object().when('supplierType', {
    is: (value) => value === SUPPLIER_TYPES.PROFESSIONAL,
    then: object().shape({
      firstName: optionalString('Hero Pilot First Name'),
      lastName: optionalString('Hero Pilot Last Name'),
      phoneNumber: optionalString('Please include a country code (e.g +64213112)'),
      email: optionalString('Hero Pilot Email'),
    }),
    otherwise: object().shape({
      firstName: requiredString('Hero Pilot First Name'),
      lastName: requiredString('Hero Pilot Last Name'),
      phoneNumber: requiredString('Hero Pilot Phone number').matches(
        CONSTANTS.REGEX.E164_PHONE_FORMAT,
        'Please include a country code (e.g +64213112)'
      ),
      email: requiredString('Hero Pilot Email'),
    }),
  }),
})

const CreateSupplier = ({ isPublishing }) => {
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()

  const initialValues = {
    companyName: '',
    abn: '',
    phoneNumber: '',
    website: '',
    address: '',
    supplierType: SUPPLIER_TYPES.PILOT,
    commercial: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    billing: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    heroPilot: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  }

  const handleSubmit = async (values) => {
    setShowModal(false)
    const payload = values
    if (values.supplierType === SUPPLIER_TYPES.PROFESSIONAL) {
      payload.heroPilot = undefined
    }
    const supplier = await supplierModule.publishSupplier(null, payload)
    if (supplier && supplier.id) {
      history.push({ pathname: `/supplier/${supplier.id}` })
    }
  }

  return (
    <>
      <FormLayoutProvider value={{ editing: true }}>
        <FormProvider
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            setShowModal(true)
          }}
        >
          {({ values, resetForm }) => (
            <>
              <SupplierLayout
                loading={isPublishing}
                hideTabs
                footer={
                  <FormAction
                    okText="Create"
                    onCancel={() => {
                      resetForm()
                      history.goBack()
                    }}
                  />
                }
              >
                <Details
                  showExtra={false}
                  title="Create New Supplier"
                  subtitle="* indicates required field"
                />
                <CommercialContact title="Commercial Contact" />
                <BillingContact title="Billing Contact" />
                {values.supplierType !== SUPPLIER_TYPES.PROFESSIONAL ? (
                  <HeroPilotContact title="Hero Pilot" />
                ) : null}
              </SupplierLayout>
              <ConfirmModal
                title="Are You Sure?"
                subtitle="Are you sure that all of the information submitted is correct?"
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                onConfirm={() => handleSubmit(values)}
              />
            </>
          )}
        </FormProvider>
      </FormLayoutProvider>
    </>
  )
}

const ConnectedCreateSupplier = connect(() => ({
  isPublishing: isPublishing$,
}))(CreateSupplier)

export { ConnectedCreateSupplier as CreateSupplier }
