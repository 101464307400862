import { connect } from '@seedcloud/stateless'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { SelectField } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { pilotList$ },
} = createCompanyModule()

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})
const Title = styled.h2(apply('my-6 text-xl font-light'))

const Connected = connect(() => ({
  pilotList: pilotList$,
}))(KeyContactsSection)

const convertPilotListToOptions = (list) =>
  list
    ?.filter(({ isActive, isBanned }) => isActive && !isBanned)
    ?.filter(({ userDetails }) => userDetails?.fullName)
    ?.map(
      ({
        userId,
        userDetails: { phoneNumber, email, fullName, firstName, lastName } = {},
      }) => ({
        id: userId,
        value: fullName,
        label: fullName,
        firstName,
        lastName,
        phoneNumber,
        email,
      })
    )

function KeyContactsSection({ id, pilotList, isEditing }) {
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    companyModule.fetchCompanyPilots(id)
  }, [])

  const pilotOptions = convertPilotListToOptions(pilotList)

  return (
    <>
      <Title>Key Contacts</Title>
      <Details>
        <Column style={apply('flex-1')}>
          <SelectField
            name="keyContact.fullName"
            label="Contact Name (required)"
            emptyOption={{ id: undefined, value: undefined, email: '', mobile: '' }}
            onChange={({
              id,
              value: fullName,
              firstName,
              lastName,
              email,
              phoneNumber,
            }) => {
              setFieldValue('keyContact', {
                id,
                fullName,
                firstName,
                lastName,
                email,
                phoneNumber,
              })
            }}
            options={pilotOptions}
            isDisabled={!isEditing}
            placeholder="Search and Select Staff"
            testId="job-client-name"
          />
          <Row>
            <FormInput
              name="keyContact.phoneNumber"
              type="text"
              label="Phone Number"
              readOnly
            />
            <FormInput name="keyContact.email" type="email" label="Email" readOnly />
          </Row>
        </Column>
      </Details>
    </>
  )
}

export { Connected as KeyContactsSection }
