import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'

import { CompanyListItem } from '../CompanyList/CompanyListItem'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { nbsCompanyList$, isLoadingNBSCompany$, order$ },
} = createCompanyModule()

const Container = styled.div(
  apply('flex flex-column mr-6 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)
const List = styled.ol(
  apply('p-0 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const ConnectedInviteCompanies = connect(() => ({
  order: order$,
  nbsCompanyList: nbsCompanyList$,
  isLoadingNBSCompany: isLoadingNBSCompany$,
}))(InviteCompanies)

const { fetchNBSCompanies, addCompany } = companyModule

function InviteCompanies({
  order,
  nbsCompanyList,
  isLoadingNBSCompany,
  inspectedEntity,
  ...props
}) {
  useLayoutEffect(() => {
    fetchNBSCompanies(null)
  }, [])

  return (
    <Container {...props}>
      <Loader
        loading={isLoadingNBSCompany}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {nbsCompanyList.map((company) => {
            const onInvite = () => {
              addCompany(company.id)
            }

            const isInvited = order.includes(company.id)

            return (
              <CompanyListItem
                key={company.id}
                company={company}
                onInvite={onInvite}
                isInvited={isInvited}
                isInvite
              />
            )
          })}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedInviteCompanies as InviteCompanies }
