import { CompanyActionsSection } from './CompanyActionsSection'
import { DetailsSection } from './DetailsSection'
import { KeyContactsSection } from './KeyContactsSection'

import { styled } from 'lib/styled'

const Container = styled.section()

function CompanyDetailsForm({ id, isEditing, isActive, isModal, ...props }) {
  return (
    <Container {...props}>
      <DetailsSection isEditing={isEditing} />

      {!isModal ? <CompanyActionsSection id={id} isActive={isActive} /> : null}

      <KeyContactsSection id={id} isEditing={isEditing} />
    </Container>
  )
}

export { CompanyDetailsForm }
