import * as Validator from 'yup'

import { formatDate } from './formatDate'

const { string, date } = Validator
export const requiredString = (label) => string().required().label(label)
export const optionalString = (label) => string().optional().label(label)
export const dateNowMinimum = () =>
  date().min(
    new Date(),
    ({ label, min }) => `${label} field must be later than ${formatDate(min)}`
  )
