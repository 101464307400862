import { styled, apply } from 'lib/styled'

export const inputSizeVariants = {
  md: {
    height: '40px',
  },
  lg: {
    height: '48px',
  },
}

export const Input = styled.input(
  apply('border text-black rounded-lg w-full', {
    border: '1px solid #CCCCCC',
    padding: '.5rem 1rem',
    fontSize: '0.9375rem',
    height: '40px',
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  }),
  ({ size = 'md' }) => inputSizeVariants[size]
)
