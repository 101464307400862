import { propOr } from '@seedcloud/ramda-extra'
import { useFormikContext } from 'formik'

import { Button } from 'components/common/Button'
import { FieldGroup, Row, TextField, SelectField } from 'components/company/common'
import { SUPPLIER_TYPES, SUPPLIER_TYPES_DISPLAY_NAMES } from 'constants/supplier'

const Details = ({
  title,
  subtitle,
  editing,
  setEditing,
  showExtra = true,
  values = {},
}) => {
  const { setFieldValue } = useFormikContext()

  const pilotServiceOptions = Object.entries(SUPPLIER_TYPES_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  return (
    <FieldGroup
      title={title}
      subtitle={subtitle}
      extra={
        !editing && showExtra ? (
          <Button onClick={() => setEditing(true)}>Edit</Button>
        ) : null
      }
    >
      <Row>
        <TextField
          label="Company Name"
          value={propOr(null, 'companyName', values)}
          name="companyName"
          required
        />
        <TextField
          label="ABN"
          value={propOr(null, 'abn', values)}
          name="abn"
          required
        />
      </Row>
      <Row>
        <TextField
          label="Phone"
          value={propOr(null, 'phoneNumber', values)}
          name="phoneNumber"
          required
        />
        <TextField
          label="Website"
          value={propOr(null, 'website', values)}
          name="website"
          required
        />
      </Row>
      <Row>
        <TextField
          label="Address"
          value={propOr(null, 'address', values)}
          name="address"
          required
        />
      </Row>
      <Row>
        <SelectField
          disabled={true}
          label="Supplier Type"
          value={
            SUPPLIER_TYPES_DISPLAY_NAMES[
              propOr(SUPPLIER_TYPES.PILOT, 'supplierType', values)
            ]
          }
          name="supplierType"
          options={pilotServiceOptions}
          emptyOption={{ value: undefined, label: '' }}
          onChange={({ value }) => {
            setFieldValue('supplierType', value)
          }}
          isSearchable={false}
          required
        />
      </Row>
    </FieldGroup>
  )
}

export { Details }
